import { createRouter, createWebHistory } from 'vue-router'

import type { NavigationGuard } from 'vue-router'

import session, { pullSession } from '../lib/session'

import Home from '../pages/Home.vue'

const requireAuthz: NavigationGuard = (_to, _from) => {
  console.log('pulling session')
  pullSession()
  console.log('session', session.value)
  if (!session.value) {
    console.log('redirecting to login')
    return { name: 'login' }
  }
}

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      name: 'home',
      path: '/',
      component: Home,
      beforeEnter: requireAuthz
    },
    {
      name: 'workout_new',
      path: '/workouts/new',
      component: () => import('../pages/WorkoutsNew.vue'),
      beforeEnter: requireAuthz
    },
    {
      name: 'workout_edit',
      path: '/workouts/edit/:workoutId',
      component: () => import('../pages/WorkoutsNew.vue'),
      beforeEnter: requireAuthz
    },

    {
      name: 'login',
      path: '/login',
      component: () => import('../pages/Login.vue')
    },
    {
      name: 'signup',
      path: '/signup',
      component: () => import('../pages/Signup.vue')
    }
    /*
  {
    path: '/:pathMatch(.*)*',
    component: () => import('./errors/404.vue'),
  },
  */
  ]
})

export default router
