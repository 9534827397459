<script setup lang="ts">
import { ref, onMounted } from 'vue'
import dayjs from 'dayjs'
import dayjsLocalFormatter from 'dayjs/plugin/localizedFormat'

import trpc from '../lib/trpc'

import type { WorkoutLifts } from 'server'

const data = ref<WorkoutLifts[]>([])
onMounted(async () => (data.value = await trpc.getWorkouts.query()))

dayjs.extend(dayjsLocalFormatter)
</script>

<template>
  <div>
    <div v-if="data" class="grid app-container gap-x-3">
      <div>
        <p class="text-blue-500">n={{ data.length }}</p>
        <sl-button href="/workouts/new">New workout</sl-button>
        <ul class="flex flex-col gap-y-4">
          <li
            v-for="[workout, lifts] in data"
            :key="workout.workout_id"
            class="border-2 border-rounded-md"
          >
            <a :href="`/workouts/edit/${workout.workout_id}`">
              <header class="text-xl underline">
                {{ dayjs(workout.created_at).format('ll') }}
              </header>
            </a>
            <ul>
              <li v-for="lift in lifts" :key="lift.lift_id">
                <div>
                  <span class="capitalize">{{ lift.exercise }}</span>
                  {{ lift.sets }}x{{ lift.reps }} {{ lift.weight }}lb
                </div>
                <div v-if="lift.notes" class="italic">Note: {{ lift.notes }}</div>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <p v-else>Loading...</p>
  </div>
</template>
